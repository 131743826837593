import React from 'react'
import RingLoader from "react-spinners/RingLoader";
import { useState, CSSProperties } from "react";


function Loader() {
    const override: CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
    };
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");
    return (
        <div style={{marginTop:"150px"}}>
            <div className="sweet-loading text-center">
                <RingLoader
                    color='#81b8ef'
                    loading={loading}
                    cssOverride={override}
                    size={80}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        </div>
    )
}

export default Loader