import React, { useState } from 'react';
import axios from 'axios';

function Register() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [imageurl, setImageUrl] = useState(null);
    const [college, setCollege] = useState('');
    const [subject, setSubject] = useState('');
    const [location, setLocation] = useState('');
    const [mode, setMode] = useState('');
    const [phonenumber, setPhoneNumber] = useState('');
    const [experience, setExperience] = useState('');
    const [agree, setAgree] = useState(false);
    const [qrCode] = useState('apni.jpg');
    const [transaction, setTransaction] = useState('');

    async function addroom() {
        if (
            name &&
            email &&
            description &&
            imageurl &&
            college &&
            subject &&
            location &&
            mode &&
            phonenumber &&
            experience &&
            agree
        ) {
            const user = {
                name,
                email,
                description,
                imageurl: imageurl instanceof Blob ? imageurl : dataURLtoBlob(imageurl),
                college,
                subject,
                location,
                mode,
                phonenumber,
                experience,
                transaction,
            };
    
            try {
                const result = await (await axios.post('http://localhost:5000/api/rooms/getallrooms', user)).data;
                console.log(result);
            } catch (error) {
                console.log(error.response.data);
            }
        }
    }
    
    // Convert data URL to Blob
    function dataURLtoBlob(dataURL) {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const b64Data = atob(arr[1]);
        let n = b64Data.length;
        const uint8Array = new Uint8Array(n);
        while (n--) {
            uint8Array[n] = b64Data.charCodeAt(n);
        }
        return new Blob([uint8Array], { type: mime });
    }
    
    function handleProfilePicChange(event) {
        const file = event.target.files[0];
        if (file) {
            // Check if the file size is less than or equal to 25KB (25 * 1024 bytes)
            if (file.size <= 25 * 1024) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const base64Image = e.target.result;
                    setImageUrl(base64Image);
                };
                reader.readAsDataURL(file);
            } else {
                alert('Image size should be less than or equal to 25KB');
            }
        }
    }

    return (
        <div>
            <div className="row justify-content-center mt-5">
                <div className="col-md-5">
                    <div className="bs">
                        <h2>Register</h2>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input
                            className="form-control"
                            placeholder="Teacher Description - college"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{ resize: 'none' }}
                        />
                        <input
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleProfilePicChange}
                        />
                        {imageurl && (
                            <img
                                src={imageurl}  // Use the base64 data directly
                                alt="Profile Pic"
                                width="100"
                            />
                        )}

                        <input
                            type="text"
                            className="form-control"
                            placeholder="college (Highest Degree)"
                            value={college}
                            onChange={(e) => setCollege(e.target.value)}
                        />
                        <select
                            className="form-control"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        >
                            <option value="">Select Subject</option>
                            <option value="physics">Physics</option>
                            <option value="chemistry">Chemistry</option>
                            <option value="mathematics">Mathematics</option>
                            <option value="biology">Biology</option>
                            <option value="computer Science">Computer Science</option>
                            <option value="english">English</option>
                            <option value="bengali">Bengali</option>
                            <option value="hindi">Hindi</option>
                            <option value="sst">SST</option>
                            <option value="com">Commerce</option>
                            <option value="eco">Economics</option>
                            <option value="geo">Geography</option>
                            <option value="ce">Competitive Exam</option>
                        </select>

                        <input
                            type="text"
                            className="form-control"
                            placeholder="Location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                        <select
                            className="form-control"
                            value={mode}
                            onChange={(e) => setMode(e.target.value)}
                        >
                            <option value="">Select Mode</option>
                            <option value="Offline">Offline</option>
                            <option value="Online">Online</option>
                        </select>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Mobile Number"
                            value={phonenumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Experience"
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                        />
                        <img className='qr-code'
                            src={qrCode}
                            alt="QR Code"
                            width="200"
                        />
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Transaction ID by Paying Rs.49"
                            value={transaction}
                            onChange={(e) => setTransaction(e.target.value)}
                        />
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={agree}
                                onChange={() => setAgree(!agree)}
                            />
                            <label className="form-check-label">
                                I hereby confirm to share my details with Apni Vidya
                            </label>
                        </div>
                        <button className="btn btn-primary mt-3" onClick={addroom}>
                            Register
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
