import React from 'react';

function About() {
  return (
    <div>
      <h1>About Us</h1>
      <p>
        Apni Vidya operates as a digital advertising agency, connecting talented teachers with eager learners. Similar to how newspapers provide a platform for businesses to showcase their advertisements, we offer a digital space where teachers can promote their services and expertise.
      </p>
      <p>
        Our mission is to facilitate educational connections and empower both teachers and students to discover new learning opportunities. We believe that education is a powerful tool, and by bringing together educators and learners, we are helping to build a brighter future.
      </p>
      <p>
        If you have any questions, concerns, or need assistance, please feel free to reach out to us at <a href="mailto:info@apnividya.in">info@apnividya.in</a>. We are here to support you on your educational journey and make your experience with Apni Vidya as seamless as possible.
      </p>
    </div>
  );
}

export default About;
