import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Homescreen from './screens/Homescreen';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Landingscreen from './screens/Landingscreen';
import Register from './screens/Register';
import Loginscreen from './screens/Loginscreen';
import Footer from './components/footer/Footer';
import TC from './screens/TC';
import About from './screens/About';
import Courses from './screens/Courses';

function App() {
  return (
    <div className="App">
      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<Homescreen />} />
          <Route path="/" element={<Landingscreen />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/login" element={<Loginscreen />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/TermsConditions" element={<TC />} />
          <Route path="/about" element={<About/>} />
        </Routes>
      </BrowserRouter>
      {/* <Footer/> */}
    </div>
  );
}

export default App;
