import React from 'react';
import { Link } from 'react-router-dom';
import {FaSearch, FaGreaterThan} from 'react-icons/fa';

function Landingscreen() {
  return (
    <div className='hellop'>
    <div className='row landing'>
      {/* <video className='myVid' autoPlay muted loop id="myVideo">
        <source src="backgroundu.mp4" type="video/mp4" />
      </video> */}
      <div className="grideer">
      <div className="col-md-12 text-center">
      <img className='bannerimage' src="/image/yozexbanner.png" alt="banner" />
      <div className='yozexhead'>
        {/* <h2 className="apni" style={{ color: 'indigo'}}>YOZEX</h2>
        <h1 style={{ color: 'grey' }}>Find the Service that suits you the best</h1> */}
        <Link to='/home'>
          <button className='btn2 btn-primary'><span className='spanbtn'>SEARCH ALL LISTING</span> <FaSearch/></button>
        </Link>
      </div>
      <div className='Home'>
        <h1 className='apni'>Home Services <FaGreaterThan/></h1>
        <div className="grid-container">
          <div className="grid-item">
            <img src="https://plus.unsplash.com/premium_photo-1663045495725-89f23b57cfc5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Plumber" />
            <h4>Plumber</h4>
          </div>
          <div className="grid-item">
            <img src="/image/Carpenter.png" alt="Carpenter" />
            <h4>Carpenter</h4>
          </div>
          <div className="grid-item">
            <img src="/image/Electrician.png" alt="Electrician" />
            <h4>Electrician</h4>
        </div>
        </div>
      </div>
      </div>
      </div>
      
    </div>
    {/* <div>
      <div className="newsep" id='tabnewsep'>
        <h2 id='apni' style={{ color: 'white'}}>
          Wanted to Register you as a Teacher
        </h2>
        <Link to='https://site.apnividya.in'>
          <button className='btn2 btn-primary'>Click Here</button>
        </Link>
      </div>
    </div> */}
    </div>
  );
}

export default Landingscreen;
