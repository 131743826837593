import React from 'react'

function Navbar() {
    return (
        <div className='navdata'>
            <nav class="navbar navbar-dark navbar-expand-lg">
                <a class="navbar-brand" href="/"><img className='logobrand' src="./apnividyalogo.png" width="40" 
                    height="40"/>Yozex</a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item active">
                            <a class="nav-link" href="https://yozex.com/">Business Listing</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/courses">Advertise</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/TermsConditions">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default Navbar