import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Room from '../components/Room';
import Loader from '../components/Loader';

function Homescreen() {

  const [rooms, setRooms] = useState([]);
  const [duplicateRooms, setDuplicateRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [type, setType] = useState('all');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = (await axios.get('https://beige-bison-gear.cyclic.app/api/rooms/getallrooms')).data;
        setRooms(data);
        setDuplicateRooms(data);
        setLoading(false);
      } catch (error) {
        setError(true);
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function filterBySearch() {
    const tempRooms = duplicateRooms.filter(room => room.description.toLowerCase().includes(searchKey.toLowerCase()));
    setRooms(tempRooms);
    
  }

  function filterByType(e) {
    setType(e); 
    if (!duplicateRooms || duplicateRooms.length === 0) {
      return;
    }
  
    if (e === 'all') {
      setRooms(duplicateRooms); // Show all rooms when 'All Subjects' is selected
    } else {
      const tempRooms = duplicateRooms.filter(room => room.subject.toLowerCase() === e.toLowerCase());
      setRooms(tempRooms); // Filter rooms based on the selected type
    }
  }
  

  return (
    <div className='container'>
      <div className="row justify-content-center mt-3">
        <div className="col-md-5">
          <input type="text" className='form-control' placeholder='Search...' value={searchKey} onChange={(e) => { setSearchKey(e.target.value) }} onKeyUp={filterBySearch} />
        </div>
        <div className='col-md-3'>
          <select className='form-control' value={type} onChange={(e)=>{filterByType(e.target.value)}}>
            <option value="all">All Subjects</option>
            <option value="physics">Physics</option>
            <option value="chemistry">Chemistry</option>
            <option value="mathematics">Mathematics</option>
            <option value="biology">Biology</option>
            <option value="computer science">Computer Science</option>
            <option value="english">English</option>
            <option value="bengali">Bengali</option>
            <option value="hindi">Hindi</option>
            <option value="sst">Social Studies</option>
            <option value="com">Commerce</option>
            <option value="eco">Economics</option>
            <option value="geo">Geography</option>
            <option value="ce">Competitive Exams</option>
          </select>
        </div>
      </div>

      <div className="row justify-content-center mt-2">
        {loading ? (<h1><Loader /></h1>) : (rooms.map(room => {
          return <div className="rajaji col-md-9 mt-2">
            <Room room={room} />
          </div>
        }))}
      </div>
    </div>
  );
}

export default Homescreen;