import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';

function Room({ room }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className='roomstyle row bs'>
            <div className="col-md-4">
                <img src={room.imageurl} className='smallimg' />
            </div>
            <div className="col-md-7">
                <h1>{room.name}</h1>
                <p>{room.college}</p>
                <h4><b>Services: </b>{room.subject}</h4>
                <h4><b>Mode: </b>{room.mode}</h4>
                <h4><b>Location: </b>{room.location}</h4>

                <div style={{ float: 'right' }}>
                    <button className='btn btn-primary' onClick={handleShow}>View Details</button>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{room.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{room.description}</Modal.Body>
                <Modal.Body><h4><b>Contact: </b>{room.phonenumber}</h4></Modal.Body>
                <Modal.Body><h4><b>Email: </b>{room.email}</h4></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default Room