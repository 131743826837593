import React from 'react'

function Loginscreen() {
  return (
    <div>
        <h1>
            Coming Soon
        </h1>
    </div>
  )
}

export default Loginscreen