import React from 'react';

function TC() {
  return (
    <div className='terms'>
      <h1>Terms &amp; Conditions</h1>
      <p>
        Terms and Conditions for Apni Vidya
        <br />
        <br />
        These Terms and Conditions ("Terms") govern your use of the Apni Vidya website ("Website") and the services provided by Apni Vidya ("Services"). By using our Website and Services, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use our Website and Services.
      </p>
      <p>
        <strong>1. Registration and Fees</strong>
        <br />
        1.1. Teachers must register on Apni Vidya and pay a non-refundable monthly fee of Rs49 to showcase their profiles on the Website.
      </p>
      <p>
        <strong>2. Profile Display</strong>
        <br />
        2.1. By registering on Apni Vidya, teachers grant us the right to display their profiles on the Website.
        <br />
        2.2. Teacher profiles may include their phone number, email address, and address to facilitate contact with users interested in their services.
        <br />
        2.3. Apni Vidya may modify, remove, or hide teacher profiles at its discretion without prior notice.
      </p>
      <p>
        <strong>3. Payments</strong>
        <br />
        3.1. Payments for the monthly fee are non-refundable, regardless of whether the teacher chooses to continue or terminate their subscription.
        <br />
        3.2. Teachers are responsible for keeping their payment information up to date. Failure to do so may result in the suspension or termination of their subscription.
      </p>
      <p>
        <strong>4. User Conduct</strong>
        <br />
        4.1. Teachers and users of Apni Vidya must conduct themselves in a respectful and professional manner when using the Website and interacting with others.
        <br />
        4.2. Users are prohibited from engaging in any illegal activities or actions that violate these Terms.
      </p>
      <p>
        <strong>5. Privacy</strong>
        <br />
        5.1. Apni Vidya will collect, use, and store personal information in accordance with our Privacy Policy. By using our Services, you consent to the collection and use of your personal information as outlined in the Privacy Policy.
      </p>
      <p>
        <strong>6. Termination</strong>
        <br />
        6.1. Apni Vidya reserves the right to suspend or terminate the accounts of teachers and users who violate these Terms or engage in any activity that is harmful to the Website or its users.
      </p>
      <p>
        <strong>7. Limitation of Liability</strong>
        <br />
        7.1. Apni Vidya is not responsible for the accuracy of information provided by teachers on their profiles, and we do not endorse any specific teacher or their services.
        <br />
        7.2. Apni Vidya is not liable for any disputes or interactions that may occur between teachers and users of the Website.
      </p>
      <p>
        <strong>8. Changes to Terms and Services</strong>
        <br />
        8.1. Apni Vidya reserves the right to update or modify these Terms and the Services offered on the Website at any time. Users will be notified of significant changes.
      </p>
      <p>
        <strong>9. Contact Information</strong>
        <br />
        9.1. If you have any questions or concerns regarding these Terms, please contact us at [contact email].
      </p>
      <p>
        By using Apni Vidya and its Services, you acknowledge that you have read, understood, and agree to these Terms and Conditions. Failure to comply with these Terms may result in the suspension or termination of your account.
      </p>
      <p>Last updated: 03/10/2023</p>
    </div>
  );
}

export default TC;
