import React from 'react'

function Courses() {
  return (
    <div className='courses'>
        <h1>
            Form is Coming Soon
        </h1>
    </div>
  )
}

export default Courses